.nav {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9; // overflow-x: hidden; overflow-y: scroll;
  transition: transform .5s linear;
  // &--banner {}

  &.fixed{
    transform: translateY(-41px);
  }
  &--top-mobile {
    width: 100%;
    // @include padblock(0.5em);
    text-align: left;
    background-color: white;
    color: $black;

    &-cont {
      display: flex;
      gap: 15px;
      padding-top: 10px;
    }

    >label {
      width: auto;
      padding-right: 1em;
      position: relative;
      z-index: 1;
      font-size: 14px;
      font-weight: 600;

      // &::after {
      //   content: "\f107";
      //   font-family: $icon;
      //   position: absolute;
      //   top: 3px;
      //   right: 1px;
      //   z-index: -1;
      // }

      select {
        font-size: 0.9em;
      }
    }

    &.personas {
      background-color: transparent;
      color: $black;
    }

    &.negocios {
      background-color: $dark;
      color: white;

      select {
        color: white;
      }
    }

    &.empresas {
      background-color: $black;
      color: white;

      select {
        color: white;
      }
    }

    @include phone {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @include tablet {
      display: none;
    }
  }

  &--top {
    width: 100%;
    @include module;
    max-width: 92%;

    @include deskOffice {
      max-width: 73% !important;
    }

    background-color: white;
    margin: 0 auto;
    @include flexbox;
    @include flow-rn;
    @include just-between;
    @include items-center;


    &-desk {
      display: none;
      @include padbox(0.5em);
      padding-bottom: 0;
      width: 100%;
      text-align: center;

      @include tablet {
        @include flexbox;
        @include flow-rn;
        @include just-between;
        @include items-center;
      }

      // @include ie11 {
      //   display: inline-block; text-align: left; position: relative;
      // }
    }

    section {
      @include flexbox;
      @include flow-rn;
      @include just-between;
      @include items-center;
      // @include ie11 { display: inline-block; }
    }

    &-first {
      @include flexbox;
      @include flow-rn;
      @include just-between;
      @include items-center;
      text-align: left;

      a {
        width: auto;
        font-size: 0.875em;
        padding-right: 2.2em;
        padding-bottom: 5px;
        font-weight: 600;
      }

      // @include ie11 { display: inline-block; }
    }

    &-second {
      width: auto;
      text-align: left;
      font-size: 0.82em;
      position: relative;
      display: none;

      @include tablet {
        @include flexbox;
        @include flow-rn;
        @include just-between;
        @include items-center;
      }

      >a {
        padding-right: 1em;
        position: relative;

        &:last-of-type {
          &::after {
            content: "\f107";
            font-family: $icon;
            padding-left: 3px;
            position: absolute;
            top: 2px;
            right: 0;
          }

          @include tablet {
            display: none;
          }

          @include desk {
            display: inline-block;
          }
        }

        span {
          display: inline-block;

          svg {
            height: 12px;
            width: auto;
            margin-right: 6px;
          }

          @include ie11 {
            svg {
              max-width: 14px;
            }
          }
        }
      }

      ul {
        position: absolute;
        top: calc(100% + 0.9em);
        right: 0;
        background-color: white;
        display: none;
        z-index: 1;

        li {
          display: inline-block;
          width: 100%;
        }

        a {
          width: 100%;
          @include padbox(0.5em);
          background-color: white;

          &:hover {
            background-color: $blue;
            color: white;
          }
        }
      }

      //&-hover:hover &-dropdown {display: inline-block;}
      // @include ie11 { display: inline-block; }
    }

    &-containerOpt {
      display: flex;
      gap: 20px;
    }

    &-third {
      display: none;
      position: relative;
      max-width: 130px;
      padding-bottom: 6px;

      @include tablet {
        @include flexbox;
        @include flow-rn;
        @include just-between;
        @include items-center;
      }

      @include phone {
        display: block;
      }

      &-options {
        &-trigger {
          font-weight: 600;
          padding-bottom: 5px;
        }

        &-dropdown {
          z-index: 2 !important;
          width: 260px !important;
          box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.15);
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
          display: block !important;
          transition: all .5s cubic-bezier(.47, 1.64, .41, .8);
          opacity: 0;
          transform: scaleY(0);
          transform-origin: top;

          &.open {
            display: block;
            transform: scaleY(1);
            opacity: 1;
          }

          li {
            display: inline-block !important;
            border: none !important;
            width: 50% !important;

            .optionsConts {
              margin: 8px;
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 0;

              &:hover {
                background-color: #F4F4F4;
                transition: .5s ease;
                border-radius: 6px;
              }

              i {
                margin-top: 8px;
              }

              a {
                white-space: nowrap;
                font-weight: 600;
              }

              p{
                font-weight: 600;
                font-size: 14px;
                padding: 0.5em;
              }
            }

            &:hover {
              background-color: transparent !important;
            }

          }
        }
      }

      &-dropdown {
        box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.15);
        transition: cubic-bezier(0.47, 1.64, 0.41, 0.8);
        -webkit-transition: cubic-bezier(0.47, 1.64, 0.41, 0.8);
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        display: block !important;
        transition: all .5s cubic-bezier(.47, 1.64, .41, .8);
        opacity: 0;
        transform: scaleY(0);
        transform-origin: top;

        &.open {
          display: block;
          transform: scaleY(1);
          opacity: 1;
        }

        li {
          div {
            margin: 0 5px;
            display: flex;
            align-items: center;

            svg {
              width: 20px;
              margin-top: 3px;
              border-radius: 3px;
            }

            img {
              width: 25px;
            }

            a {
              margin-top: 3px;
            }
          }
        }
      }

      &-trigger {

        // &.active{
        //   &::after{
        //     transition: transform 0.5s ease;
        //     transform: rotate(180deg);
        //   }
        // }
        padding-right: 0;

        svg {
          border-radius: 3px;
          margin-right: 0px !important;
        }
      }

      >a {
        &:first-of-type {
          color: $blue;
          font-size: 0.875em;

          span {
            display: inline-block;
            vertical-align: middle;

            svg {
              height: 18px;
              width: auto;
              max-width: 14px;
              margin-right: 5px;
            }
          }
        }

        &:last-of-type {
          color: $black;

          svg {
            height: 1.3em;
            width: auto;
            max-width: 20px;
            border-radius: 6px;
            margin-right: 9px;
          }

          display: flex;
          align-items: center;
          gap: 7px;

          i {
            font-size: .625rem;
            transition: all .3s ease;
          }

          &.active {
            i {
              transform: rotate(180deg);
            }
          }
        }
      }

      ul {
        width: 200px;
        position: absolute;
        top: calc(100% + 0em);
        right: 0;
        background-color: #fff;
        display: none;
        z-index: 1;

        @include desk {
          width: 200px;
        }

        li {
          display: flex;
          width: 100%;
          cursor: pointer;
          text-align: left;
          transition: all .8s ease-out;
          -webkit-transition: all .8s ease;
          border-top: #F4F4F4 1px solid;

          a {
            color: #000;
            padding: 8px 12px;
            @include padblock(0.5em);
            font-size: 14px;
          }

          &:hover {
            background-color: #F4F4F4;
          }
        }
      }

      // @include ie11 {
      //   display: inline-block; padding-left: 31%; @include transform(translateY(5px));
      //   > a:first-of-type { @include transform(translateY(-0.4rem)); }
      // }
    }
  }

  &--main {
    width: 100%;
    background-color: $red;
    color: white;
    position: relative;
    box-shadow: 4px 6px 10px -6px rgba(0, 0, 0, 0.62);
    -webkit-box-shadow: 4px 6px 10px -6px rgba(0, 0, 0, 0.62);
    -moz-box-shadow: 4px 6px 10px -6px rgba(0, 0, 0, 0.62);

    // @include ie11 { text-align: center; }
    &-content {
      @include flexbox;
      @include flow-rw;
      @include just-between;
      position: relative;
      overflow: visible;
      height: auto;
      @include module;
      max-width: 91%;
      @include padbox(0.5rem);

      @include deskOffice {
        max-width: 73% !important;
      }

      @include tablet {
        padding: 0;
        max-width: 91%;
      }

      @include phone {
        justify-content: flex-start;
        align-items: center;
        padding: 0;
      }

      .nav--links {
        a {
          @include phone {
            padding-bottom: 12px !important;
          }
        }
      }
    }
  }

  &--home {
    color: $black;
    margin: .5rem;

    @include tablet {
      display: none;
    }

    &::before {
      content: '<';
      padding-right: .5rem;
    }
  }

  &--search {
    &.search--header {
      background-color: unset;
      width: 100%;

      @include phone {
        margin-top: 0;
        position: relative;
        padding: 0;
        text-align: right;
        left: unset;
      }

      .search--box {
        width: auto;

        i {
          margin: 4px;
          color: #3c3c3c;
        }

        &-results {
          left: 0;
          text-align: left;
        }

        @include phone {
          width: auto !important;
          display: inline-block;

          .showBar {
            #search-box {
              width: calc(50vw - .5em) !important;
              padding: 7px;
              transition: all 0.5s;
              border-color: #afafaf;
            }
          }
        }

        label {
          width: auto;
          min-width: 195px;
          height: 15px;
          border: none;
          background-color: #f0f0f0;
          border-radius: 12px;
          display: block;
          width: 100%;
          padding: 7px 0;

          @include phone {
            padding-right: 0;
            text-align: right;
          }

          input {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            font-size: 16px;
            font-size: 1rem;
            font-family: "Roboto", sans-serif;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            padding: 0 16px 0 16px;
            background: transparent;
            border: none;
            outline: none;

            @include phone {
              margin: 0;
              width: 0 !important;
              padding: 0;
              transition: all 0.5s;
            }
          }
        }
      }

      button {
        display: none;
      }

      .ButtonSearch {
        display: block;
        border: none;
        background-color: transparent;
        color: #606159;
        right: unset;
        left: 5px;
        padding: 0;

        @include phone {
          right: 5px;
          left: unset;
        }

        @include desk {
          pointer-events: none;
          cursor: default;
        }
      }
    }
  }

  &--links {
    display: flex;
    gap: 10px;
    align-items: center;
    padding-top: 8px;
    margin-bottom: 3px;

    .link-container {
      &::before {
        position: absolute;
        // transform: translateX(-25%);
        // transition: all .3s ease;
        content: "";
        bottom: 0;
        width: 0;
        height: 0;
        background-color: transparent;
        border-style: solid;
        border-width: 0;
        border-color: transparent transparent #fff transparent;
        opacity: 0;
        border-width: 0 12px 12px 12px;
        bottom: 0;
      }
      &:hover::before{
        opacity: 1;
        border-width: 0 12px 12px 12px;
        bottom: 0;
      }
    }

    @include tablet {
      .miclaro--mobile {
        display: none;
      }
    }

    @include phone {
      justify-content: space-between;
      width: 85%;

      .miclaro--mobile {
        &-cont{
          display: flex;
          align-items: center;
          i{
            font-size: 1.5em;
          }
        }
        font-size: 16px;
        white-space: pre;
        font-weight: 700;

        a {
          display: block;
        }
      }
    }

    &-menu {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.95);
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
      left: calc(0px - 5.3%);
      top: 52px;
      display: none;
      z-index: 1;
      width: 110.5%;
      min-height: 420px;
      transition: all .5s ease;
      color: #000;

      @include deskOffice {
        left: calc(0px - 19.1%);
        width: 137.8%;
      }

      .options-container-menu {
        display: flex;
        gap: 40px;
        width: 70%;
        flex-wrap: wrap;
      }

      .container-menu {
        margin: 30px 30px 30px 5%;

        @include deskOffice {
          margin: 30px 30px 30px -1%;
          display: flex;
          justify-content: center;
        }

        &-option {
          &-tittle {
            display: flex;
            gap: 30px;
            align-items: center;

            a {
              font-weight: 600;
              color: #000;
              margin-bottom: 15px;
              font-size: 20px;
              padding: 0;
            }

            img {
              width: 30px;
              margin: 0 10px;
            }
          }

          &-items {
            ul {
              li {
                margin-bottom: 10px;

                .target-option {
                  color: hsl(0, 0%, 0%);
                  margin: 6px 0;
                  padding: 0;
                  cursor: pointer;
                  display: inline-block;
                  color: #000;
                  text-decoration: none;
                  font-size: 16px;
                  font-weight: normal;
                }

                .target-option::after {
                  content: '';
                  display: block;
                  width: 0;
                  height: .5px;
                  background: #000;
                  transition: width .3s;
                }

                .target-option:hover::after {
                  width: 100%;
                }
              }
            }

          }
        }
      }
    }

    a {
      font-size: 16px;
      font-weight: 600;
      color: white;
      @include padbox(0.75em 0.5em);
      height: 100%;
      display: none;

      i {
        margin: 3px 5px;
        font-size: 11px;
      }

      &.active {
        background-color: white;
        color: $red;
      }

      @include tablet {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &--logo {
    display: inline-block !important;
    padding: 0 0 0 0.5em !important;
    vertical-align: text-bottom;
    margin-right: 1em;

    @include desk {
      padding: 0 !important;
    }

    svg {
      height: 30px;
      width: auto;
      display: block;


      @include ie11 {
        max-width: 84px;
      }
    }

    &:hover {
      background-color: $red !important;
    }
  }

  &--actions {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;

    @include tablet {
      position: static;
      width: auto;
      @include flexbox;
      @include flow-rw;
      @include just-between;
      @include items-center;
    }

    .nav--trigger-search {
      display: none;
      font-family: $icon;

      @include tablet {
        @include padblock(0.5em);
        color: white;
        font-size: 1.5em;
        margin-right: 0.5em;
      }

      @include tablet {
        display: none;
      }
    }
  }

  &--cart {
    position: relative;
    background-color: #B52217;
    color: white;
    border-radius: 20px;
    margin-right: 0.5em;
    @include padbox(0.6rem 0.5rem);
    height: 40px;
    display: none;
    padding: 9px 15px 9px 15px;
    align-items: center;

    span{
      font-size: 16px;
      font-weight: 600;
    }

    @include tablet {
      display: flex;
      gap: 10px
    }

    @include desk {
      width: auto;
    }

    @include phone {
      background-color: transparent !important;
      .nav--qty{
        top: 6px;
        left: 16%;
      }
      .cart-tittle{
        display: none;
      }
    }

    svg {
      height: 2rem;
      width: auto;
      display: block;
      max-width: 40px;
    }

    span {
      display: inline-block;
      // svg {height: 1em; width: auto; display: block;}
    }

    @include desk {
      &:hover {
        background-color: white;

        * {
          color: #000;
          fill: #000;
        }
      }
    }
  }

  &--qty {
    position: absolute;
    top: 0;
    left: -73px;
    right: 0;
    text-align: center;
    color: white;
    font-size: 1em;
    padding: 0 !important;
    font-family: $font !important;
  }

  &--grid {
    display: flex;

    @include phone {
      padding-top: 4em;
      width: 100%;
    }
  }

  &--search {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    flex: 1;
    @include padblock(0 0.45em);

    @include tablet {
      display: inline-block;
      position: static;
      top: 100%;
    }

    @include desk {
      padding: 0;
      top: 100%;
    }

    @include phone {
      display: inline-block;
      margin-top: 4em;
    }
  }

  &--miclaro {
    display: flex;
    align-items: center;
    gap: 6px;
    @include padbox(0.5em);
    background-color: #B52217;
    color: white;
    margin-left: 0.5em;
    height: 40px;
    border-radius: 20px;
    position: relative;

    i {
      font-size: 1.5em;
      gap: 10px;
    }

    @include phone {
      display: none;
    }

    @include tablet {
      // display: inline-block;
      font-size: 1em;
      padding: 9px 30px 9px 25px;
      line-height: 1.4;
      height: 40px;
    }

    // @include desk {}
    span {
      position: relative;
      font-weight: 600;

      &::after {
        // content: "autogestion";
        position: absolute;
        left: 0;
        right: 0;
        top: 90%;
        text-align: center;
        font-size: 0.7rem;
      }
    }

    &:hover,
    &.active {
      background-color: white;
      color: #000;
    }

    // &::after {content: "autogestión"; font-size: 60%; display: inline-block; width: 100%; text-align: center;
    //   position: absolute; left: 0; right: 0; top: 64%;
    // }
    // &-logout { @include padbox(0.7rem); font-size: 1.6rem; position: relative; color: white; background-color: $wine; display: none;
    //   @include tablet { display: inline-block; margin-left: 0.5rem; }
    //   @include desk { margin-left: 0; }
    //   &::before { content: "\f08b"; font-family: $icon; }
    //   &::after { content: "cerrar sesión"; font-size: 0.8rem; @include padblock(0.5rem); background-color: white; color: $wine; text-align: center;
    //     position: absolute; top: 100%; right: 3rem; width: 6rem; visibility: hidden; opacity: 0; @include trans;
    //   }
    //   &:hover { background-color: white; color: $wine;
    //     &::after {visibility: visible; opacity: 1; right: 0; @include trans;}
    //   }
    // }
    // &-logout { display: inline; visibility: hidden;}
  }

  &--trigger {
    a {
      color: white;
      font-size: 1.5em;

      span {
        @include padblock(0 0.7em 0 0);
        font-family: $icon;

        &::before {
          min-width: 24px;
          display: inline-block;
        }
      }
    }

    @include tablet {
      display: none;
    }

    &-menu {
      @include tablet {
        display: none;
      }
    }
  }

  // -- Menu

  &--menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: transparent;
    height: 0;

    @include phone {
      top: 0;
      z-index: 1;
      height: auto;
      transform: translateX(-110%);
      transition: all .5s ease;

      &.active {
        transform: translateX(0);
      }

      &-tittle {
        background-color: #DA291C;
        padding: 10px 16px;
        display: flex;
        justify-content: space-between;

        p,
        i {
          color: #fff;
          font-weight: 700;
          font-size: 1rem;
        }

        i {
          font-size: 1.25rem;
        }
      }

      &-body {
        background-color: #fff;
        height: calc(1000vh - 100px);

        .menu-body {
          &-head {
            display: flex;
            position: relative;
            left: initial;
            top: initial;
            padding-top: 10px;
            width: 100%;

            li {
              width: 50%;
              padding: 0;
              border-bottom: 4px solid #BBB;

              a {
                text-align: center;
                color: #000;
                padding: 16px 12px;
                display: block;
                font-weight: 700;
                position: relative;
                display: block;
                font-size: 16px;
                width: 100%;
              }
            }
          }

          &-search {
            padding: 32px 16px 12px;

            .search--box {
              width: 100% !important;
              height: 45px !important;
              border-radius: 12px !important;
              border: 1px solid #BBB !important;

              .ButtonSearch {
                right: auto !important;
                left: 10px;
                font-size: 1.5rem;
              }

              .labelSearchBar {
                background-color: transparent !important;

                input {
                  width: 90% !important;
                  left: 55px !important;
                  font-size: 1rem;
                }
              }
            }
          }

          &-items {
            &-list {
              transition: all .3s ease;

              &.hideMenu {
                transform: translateX(-100%);
              }

              .subMenu {
                transform: translateX(500%);
                position: absolute;
                width: 100%;
                top: 1.85%;
                transition: all .5s ease;

                &.open {
                  transform: translateX(100%);
                }

                &-tittle {
                  display: flex;
                  justify-content: space-between;
                  padding: 20px 16px;

                  .backMenu {
                    color: #0097A9;
                    display: flex;
                    align-items: center;

                    i {
                      font-size: 1.25rem;
                    }
                  }
                }

                &-item {
                  border-bottom: 4px solid #F4F4F4;

                  li {
                    display: flex;
                    justify-content: space-between;
                    padding: 20px 16px;

                    i {
                      transition: all .5s ease;
                      color: #000;
                      font-size: 1.25rem;
                    }
                    a{
                      font-size: 16px;
                      font-weight: 500;
                    }
                  }

                  &.active {
                    .display-innerItem {
                      display: block;
                      padding: 0 20px;
                      a{
                        font-size: 16px;
                        margin: 16px;
                      }
                    }

                    li {
                      i {
                        transform: rotate(180deg);
                      }
                    }
                  }

                  .display-innerItem {
                    padding: 16px 16px;
                    transition: all .5s ease-in-out;
                    display: none;

                    a {
                      font-weight: 400;
                    }
                  }


                  &:first-child {
                    border-top: none;
                  }

                }
              }

              &-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-top: 4px solid #F4F4F4;

                &:first-child {
                  border: none !important;
                }

                a {
                  padding: 20px 16px;
                  font-size: 1rem;
                  font-weight: 500;
                }

                i {
                  color: #000;
                  font-size: 1.25rem;
                  margin-right: 20px;
                }
              }

              .item0 {
                border: none !important;
              }
            }
          }
        }
      }
    }

    @include tablet {
      display: none;
      background-color: white;
      height: auto;
    }

    &-content {
      @include transform(translateX(-100%));
      @include trans;

      &.active {
        @include transform(translateX(0));
        @include trans;
        display: block
      }

      @include tablet {
        @include transform(translateX(0));
        background-color: white;
      }
    }

    &-list {
      width: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      max-height: 60vh;
      display: none;
      background-color: white;

      @include tablet {
        @include padblock(2em 0 0.5em 0);
        @include module;
        overflow: hidden;
        height: 400px;
        max-width: 1140px;
        @include flexbox;
        @include flow-cw;
        @include just-start;
        @include items-start;
      }
    }

    &-list-tienda {
      width: 70%;
      overflow-x: hidden;
      overflow-y: scroll;
      max-height: 60vh;
      display: none;
      background-color: white;

      @include tablet {
        @include padblock(2em 0 0.5em 0);
        @include module;
        overflow: hidden;
        height: 400px;
        margin-left: 100px !important;
        @include flexbox;
        @include flow-cw;
        @include just-start;
        @include items-start;
      }

      @include desk {
        max-height: 350px;
        margin-left: 100px !important;
      }

      @include phone {
        width: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        max-height: 60vh;
        display: none;
        background-color: white;
      }
    }

    &-title {
      width: 100%;
      position: relative;
      @include padblock(0.5em);
      border-top: 1px solid white;
      background-color: $red;
      color: white;
      font-size: 1.3em;

      @include tablet {
        display: none;
      }

      &.wine {
        background-color: $wine;

        &.miclaro {
          span:first-of-type {
            position: relative;

            &::after {
              // content: "autogestion";
              position: absolute;
              left: 110%;
              top: 0.45rem;
              text-align: left;
              font-size: 0.7rem;
            }
          }
        }
      }

      span {
        display: inline-block;

        svg {
          height: 0.9em;
          width: auto;
          display: block;
        }
      }

      .mobile-icon {
        position: absolute;
        top: 0.6em;
        right: 1.5em;
        font-family: $icon;
      }

      .nav--qty {
        position: absolute;
        top: 0.6rem;
        right: 1.8rem;
        left: auto;
        font-size: 15px;
        font-weight: 500;
        padding: 4px !important;
        background-color: white;
        color: $red;
      }
    }

    &-links {
      position: relative;

      .desk {
        display: none;
      }

      @include tablet {
        width: 32%;
        border-right: 1px solid $light;
        @include padblock(0 0.5em 0 2em);
        margin-bottom: 4rem;

        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          margin-bottom: 2rem;
        }

        &:last-of-type {
          border-right: none;
        }

        .desk {
          display: inline-block;
          font-size: 80%;
          @include transform(translateX(6px));
        }

        .icon {
          position: absolute;
          left: 0;
          top: 0;
          text-align: center;
          width: 21px;

          svg {
            display: inline-block;
            height: auto;
            width: 100%;
            max-width: 21px;
            max-height: 21px;
          }
        }

        .icon.after {
          top: 4em;
          padding: 0;
        }
      }

      @include desk {
        padding: 0 0.5em 0 3em;
        width: 24%;

        .icon {
          width: 34px;

          svg {
            max-width: 34px;
            max-height: 34px;
          }
        }
      }

      li {
        display: inline-block;
        width: 100%;
        border-top: 1px solid $gray;
        position: relative;

        @include tablet {
          border-top: none;
          font-size: 90%;

          &.after {
            padding-top: 1.5em;
          }
        }

        @include desk {
          font-size: 100%;
        }

        a {
          @include padblock(0.85em 3.2em);
          font-size: 0.9em;

          &::after {
            content: "\f105";
            font-family: $icon;
            position: absolute;
            top: 0.75em;
            right: 1.25em;
            font-size: 1.25em;
          }

          @include tablet {
            padding: 0.25em;
            font-size: 1em;

            &::after {
              display: none;
            }
          }
        }

        b {
          font-size: 1.2em;
          font-weight: 400;
          @include transform(translateX(-1em));

          @include tablet {
            @include transform(translateX(0));
          }
        }

        p {
          @include tablet {
            font-size: 111%;
          }
        }

        ul {
          @include desk {
            margin-bottom: 5px;
          }

          li {
            @include phone {
              background: #f2f2f2 !important;

              a {
                padding-left: 4rem;
              }
            }

            @include tablet {
              font-size: 0.8125rem;
              padding: 5px;
              line-height: 0;
            }
          }
        }
      }
    }

    &-links-tienda {
      position: relative;

      .desk {
        display: none;
      }

      @include tablet {
        width: 16%;
        border-right: 1px solid $light;
        @include padblock(0 0.5em 2em 2em);
        margin-bottom: 8rem;

        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          margin-bottom: 2rem;
        }

        &:last-of-type {
          border-right: none;
        }

        .desk {
          display: inline-block;
          font-size: 80%;
          @include transform(translateX(6px));
        }

        .icon {
          position: absolute;
          left: 0;
          top: 0;
          text-align: center;
          width: 21px;

          svg {
            display: inline-block;
            height: auto;
            width: 100%;
            max-width: 21px;
            max-height: 21px;
          }
        }

        .icon.after {
          top: 4em;
          padding: 0;
        }
      }

      @include desk {
        padding: 0 0.5em 0 3em;
        width: 33%;

        .icon {
          width: 34px;

          svg {
            max-width: 34px;
            max-height: 34px;
          }
        }
      }

      li {
        display: inline-block;
        width: 100%;
        border-top: 1px solid $gray;
        position: relative;

        @include tablet {
          border-top: none;
          font-size: 90%;

          &.after {
            padding-top: 1.5em;
          }
        }

        @include desk {
          font-size: 100%;
        }

        a {
          @include padblock(0.85em 3.2em);
          font-size: 0.9em;

          &::after {
            content: "\f105";
            font-family: $icon;
            position: absolute;
            top: 0.75em;
            right: 1.25em;
            font-size: 1.25em;
          }

          @include tablet {
            padding: 0.25em;
            font-size: 1em;

            &::after {
              display: none;
            }
          }
        }

        b {
          font-size: 1.2em;
          font-weight: 400;
          @include transform(translateX(-1em));

          @include tablet {
            @include transform(translateX(0));
          }
        }

        p {
          @include tablet {
            font-size: 111%;
          }
        }

        ul {
          @include desk {
            margin-bottom: 5px;
          }

          li {
            @include phone {
              background: #f2f2f2 !important;

              a {
                padding-left: 4rem;
              }
            }

            @include tablet {
              font-size: 0.8125rem;
              padding: 5px;
              line-height: 0;
            }
          }
        }
      }
    }

    &-login {
      width: 100%;
      display: none;
      @include module;
      height: 0;
      text-align: right;

      .container {
        display: inline-block;
        width: auto;

        // @include flexbox; @include flow-cn; @include just-start; @include items-end; // @include module;
        a {
          @include padblock(1rem);
          font-size: 0.75rem;
          color: white;
          background-color: $black;
        }
      }
    }

    &-slogan {
      width: 100%;
      @include padbox(0.5em);
      display: none;
      background-color: $blue;
      color: white;
      font-size: 0.8em;
      text-align: left;

      @include tablet {
        display: inline-block;
      }

      p {
        @include module;
      }
    }
  }

  &--login {
    width: 100%;
    display: inline-block;
    background-color: white;
    padding: 1em 0 2em 0;

    @include tablet {
      width: 40%;
    }

    @include desk {
      width: 32%;
    }

    form {
      width: 100%;
      @include flexbox;
      @include flow-rw;
      @include just-between;
      @include items-start;
      @include padbox(1em);

      header {
        display: inline-block;
        width: auto;

        span {
          svg {
            width: 28px;
            height: auto;
          }
        }

        h2 {
          display: inline-block;
          font-size: 1.2em;
          font-weight: 400;
          padding: 7px 0 0 7px;
          vertical-align: top;
        }
      }

      aside {
        display: inline-block;
        width: auto;

        label {
          border-bottom: 1px solid $gray;
          position: relative;
          width: 50px;
          text-align: center;

          span {
            font-size: 0.9em;
            font-weight: 500;
            @include padbox(0.25em 0.5em);
            color: $grey;
            @include trans;

            &::after {
              content: "";
              display: inline-block;
              width: 100%;
              height: 4px;
              position: absolute;
              top: 100%;
              left: 0;
              right: 0;
              background-color: transparent;
              @include trans;
            }
          }

          input {
            display: none;

            &:checked+span {
              color: $black;

              &::after {
                background-color: $red;
              }
            }
          }
        }
      }

      section {
        width: 100%;
        text-align: left;

        p {
          width: 100%;
          font-size: 0.9em;
          font-weight: 400;
          margin-bottom: 4px;
        }

        label {
          width: 100%;
          margin-bottom: 1.5em;

          input {
            border: 1px solid $gray;
          }
        }
      }

      footer {
        width: 100%;

        div {
          width: 100%;
          text-align: right;

          p {
            text-align: center;
            width: 100%;
            font-weight: 400;
          }

          a {
            font-size: 0.9em;
            color: $blue;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        p {
          width: 100%;
          text-align: left;
          font-size: 0.9em;

          a {
            color: $blue;

            &::after {
              content: "\f105";
              font-family: $icon;
              padding-left: 6px;
              vertical-align: middle;
              display: inline-block;
              @include trans;
            }

            &:hover {
              &::after {
                @include transform(translateX(6px));
              }
            }
          }
        }

        button {
          width: 100%;
          @include padblock(0.8em);
          margin: 0.5em 0;
          color: white;
          font-weight: 300;
          text-align: center;

          span::after {
            display: inline-block;
            font-family: $icon;
            padding-left: 6px;
            @include trans;
          }

          &:first-of-type {
            background-color: $red;

            span {
              &::after {
                content: "\f105";
              }
            }
          }

          // FACEBOOK
          // &:last-of-type {background-color: $facebook;
          //   span {
          //     &::before {content: "\f09a"; font-family: $icon; padding-right: 6px;}
          //     &::after {content: "\f105";}
          //   }
          // }
          &:hover {
            span::after {
              @include transform(translateX(6px));
            }
          }
        }
      }
    }

    &-aside {
      display: none;

      @include tablet {
        @include padbox(0em 1em 0em 0);
        margin: 2em 0;
        width: 60%;
        border-right: 1px solid $gray;
        @include flexbox;
        @include flow-rw;
        @include just-between;
        @include items-center;
      }

      @include desk {
        width: 68%;
      }

      picture {
        width: 34%;

        @include desk {
          width: 28%;
        }
      }

      section {
        display: inline-block;
        width: 58%;

        @include desk {
          width: 68%;
          @include flexbox;
          @include flow-rw;
          @include just-between;
          @include items-center;
        }

        div {
          width: 100%;
          text-align: left;
          margin: 0.5em 0;
          @include flexbox;
          @include flow-rn;
          @include just-start;
          @include items-center;

          @include desk {
            width: 48%;
          }

          span {
            background-image: url($sprite-icons);
            background-repeat: no-repeat;
            margin-right: 6px;
          }

          p {
            font-size: 0.8em;
            display: inline-block;
            vertical-align: super;
          }

          &:nth-of-type(1) span {
            @include size(25px, 24px);
            background-position: -1px -2px;
          }

          &:nth-of-type(2) span {
            @include size(25px, 24px);
            background-position: -1px -27px;
          }

          &:nth-of-type(3) span {
            @include size(25px, 22px);
            background-position: -2px -55px;
          }

          &:nth-of-type(4) span {
            @include size(25px, 24px);
            background-position: -5px -79px;
          }

          &:nth-of-type(5) span {
            @include size(25px, 25px);
            background-position: -1px -106px;
          }

          &:nth-of-type(6) span {
            @include size(25px, 24px);
            background-position: -1px -135px;
          }
        }
      }
    }
  }

  &--categories {
    background-color: $red;
    border-radius: 5px;

    @include phone {
      width: 100%;
    }
  }

  &--categories span {
    background-color: $red;
    color: white;
    border-radius: 10px;

    &:last-of-type {
      padding: 1em;
      padding-left: 0.5em;
      padding-top: 0.75em;
      padding-right: 1.5em;
    }

    margin-left: 0.5em;
  }

  &--category {
    display: inline-flex;
    align-items: center;

    img {
      height: 32px;
      width: 32px;
    }
  }

  &--category-box {
    padding: 1em;
    margin: 1em;
    background-attachment: $blue2;
    font-size: 0.7em;
    color: $gray;
    border-radius: 5px;
    font-family: $font;
    font-weight: bold;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: none;

    &:hover,
    a:hover,
    &.active {
      color: $black;
      border-bottom: 2px solid $red;
    }

    &:first-child {
      margin-left: 0;
    }

    @include phone {
      margin-left: 0;
      width: 100%;
      text-align: end;
      padding-left: 0;
    }

    @include desk {
      text-align: center;
    }
  }

  &--btn-back-comparador {
    width: 12em;
    padding: 0.3em;
    border: 2px solid $blue;
    margin: 1em;
    background-attachment: $blue2;
    font-size: 0.7em;
    color: $blue;
    border-radius: 5px;
    font-family: $font;
    font-weight: bold;
    cursor: pointer;
    transition: none;

    &:hover,
    a:hover {
      background-color: $blue !important;
      color: white !important;
    }

    &:first-child {
      margin-left: 0;
    }

    @include phone {
      margin-left: 0;
      width: 100%;
      text-align: end;
      padding-left: 0;
    }

    @include desk {
      text-align: center;
    }
  }

  &--btn-compare {
    width: 10em;
    padding: 0.7em;
    border: 1px solid $red;
    margin: 1em;
    background-attachment: $blue2;
    font-size: 0.8em;
    color: $red;
    border-radius: 5px;
    font-family: $font;
    cursor: pointer;
    transition: none;

    &:hover,
    a:hover {
      background-color: $red !important;
      color: white !important;
    }

    &:first-child {
      margin-left: 0;
    }

    @include phone {
      // max-width: 15.4em;
      text-align: center;
      display: block;
      margin: 0em !important;
      padding: 0.6em;
      width: 100%;
    }

    @include desk {
      text-align: center;
    }
  }

  &--btn-delete {
    color: $blue;
    text-decoration: underline;
    text-underline-offset: 3px;
    padding-bottom: 22px;
  }

  &--btn-red-compare {
    padding: 0.6em;
    box-sizing: border-box;
    display: inline-block;
    // width: 14em;
    width: 100%;
    text-align: center;
    transition: all 0.3s ease;
    background-color: $red;
    color: white;
    border: 1px solid $red;
    border-radius: 5px !important;

    @include phone {
      width: 13em;
      min-width: 10em;
      font-size: 10px !important;
    }
  }

  &--center-btn {
    display: flex !important;
    justify-content: center !important;
  }
}