// --- FLEXBOX
@mixin flexbox {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}
@mixin flexbox_i {
	display: -webkit-box !important;
	display: -moz-box !important;
	display: -ms-flexbox !important;
	display: -webkit-flex !important;
	display: flex !important;
}
// -- flex-flow
@mixin flow-rw {
	-webkit-flex-flow: row wrap;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
}
@mixin flow-rn {
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
}
@mixin flow-cw {
	-webkit-flex-flow: column wrap;
	-ms-flex-flow: column wrap;
	flex-flow: column wrap;
}
@mixin flow-cn {
	-webkit-flex-flow: column nowrap;
	-ms-flex-flow: column nowrap;
	flex-flow: column nowrap;
}

@mixin flow-rwr {
	-webkit-flex-flow: row-reverse wrap;
	-ms-flex-flow: row-reverse wrap;
	flex-flow: row-reverse wrap;
}
// -- justify-content
@mixin just-center {
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}
@mixin just-start {
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}
@mixin just-end {
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}
@mixin just-around {
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}
@mixin just-between {
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
// -- align-content
@mixin align-center {
	-webkit-align-content: center;
	-ms-flex-line-pack: center;
	align-content: center;
}
@mixin align-start {
	-webkit-align-content: flex-start;
	-ms-flex-line-pack: start;
	align-content: flex-start;
}
@mixin align-end {
	-webkit-align-content: flex-end;
	-ms-flex-line-pack: end;
	align-content: flex-end;
}
@mixin align-around {
	-webkit-align-content: space-around;
	-ms-flex-line-pack: distribute;
	align-content: space-around;
}
@mixin align-between {
	-webkit-align-content: space-between;
	-ms-flex-line-pack: justify;
	align-content: space-between;
}
// -- align-items
@mixin items-center {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}
@mixin items-start {
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}
@mixin items-end {
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}
@mixin items-stretch {
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}
@mixin items-base {
	-webkit-box-align: baseline;
	-webkit-align-items: baseline;
	-ms-flex-align: baseline;
	align-items: baseline;
}
// -- flex-gsb
@mixin flex-gsb($grow, $shrink, $basis) {
	-webkit-box-flex: $grow;
	-webkit-flex: $grow $shrink $basis;
	-ms-flex: $grow $shrink $basis;
	flex: $grow $shrink $basis;
}
@mixin flex-grow($n) {
	-webkit-box-flex: $n;
	-webkit-flex-grow: $n;
	-ms-flex-positive: $n;
	flex-grow: $n;
}

// --- FLEX CHILD

// -- align-self
@mixin self-center {
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
}
@mixin self-start {
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
}
@mixin self-end {
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end;
}
@mixin self-stretch {
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
}
@mixin self-base {
	-webkit-align-self: baseline;
	-ms-flex-item-align: baseline;
	align-self: baseline;
}
// --- order
@mixin order($order) {
	// -webkit-box-ordinal-group: $orderbox;
	-webkit-order: $order;
	-ms-flex-order: $order;
	order: $order;
}

// --- CUSTOM
@mixin perfect-center {
	@include flexbox; @include just-around; @include align-around; @include items-center;
}

.cart--head-redes-sociales {
	flex-flow: wrap;
}